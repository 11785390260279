@import "/src/styles/mixin";

.title {
  @include font30;
  margin-bottom: 40px;
}

.list {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 40px 32px;

  @media only screen and (min-width: 768px) and (max-width: 1023.98px) {
    gap: 32px 24px;
  }

  @media only screen and (min-width: 320px) and (max-width: 767.98px) {
    gap: 16px 12px;
  }
}
